import Head from 'next/head';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';

export default function Custom404() {
  const router = useRouter();
  Sentry.captureMessage('404 page hit', 'warning', { url: router.asPath });

  return (
    <div>
      <Head>
        <title>404 Error</title>
        <meta property="og:title" content="StartScreen" />
        <meta
          property="og:description"
          content="The next-generation employee training platform."
        />
      </Head>
      <section className="aliased flex items-center h-[100vh] p-16 bg-gradient-to-b from-[#12132d] via-[#000212] to-[#000212] text-gray-100">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h2 className="mb-8 font-extrabold text-9xl text-gray-100">404</h2>
            <p className="text-2xl font-semibold md:text-3xl mb-8">
              We couldn't find this page.
            </p>
            <a
              href="/"
              className="active:scale-95 cursor-pointer px-8 py-3 font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 text-white transition-all duration-200 ease-in-out"
            >
              Back to website
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
